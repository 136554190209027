import PopUpModalContainer from "../ModalPage/PopUpModalContainer";
import DialogManager from "../dialog/DialogManager";


export const actionModal = (message, action = () => console.log()) => {
  return DialogManager.push(
    <PopUpModalContainer
      content={message}
      onClose={(value) => {
        if (value === "확인") {
          action();
        }
      }}
    />
  );
};
