import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import SafeReport from "./views/recentInfo/safeReport";

const Home = React.lazy(() => import('./views/installer/Home/Home'));
const Login = React.lazy(() => import('./views/installer/Login/Login'));
const SelfAuthentication = React.lazy(() => import('./views/installer/Login/SelfAuthentication'));
const Authentication = React.lazy(() => import('./views/installer/Login/Authentication'));
const Complete = React.lazy(() => import('./views/installer/Login/Complete'));
const Agree = React.lazy(() => import('./views/installer/Login/Agree'));

const NoMatch = React.lazy(() => import('./views/NoMatch'));
const CarInfoSelect = React.lazy(() => import('./views/installer/CarInfoSelect/index'));
const InfoUpload = React.lazy(() => import('./views/installer/edge_connecting/upload'));
const InfoLEDCheck = React.lazy(() => import('./views/installer/edge_connecting/LedCheck'));
const ConnectedCheck = React.lazy(() => import('./views/installer/edge_connecting/ConnectedCheck'));
const LEDComplete = React.lazy(() => import('./views/installer/edge_connecting/LedComplete'));

const Timeline = React.lazy(() => import('./views/timeline/Timeline'));
const Report = React.lazy(() => import('./views/timeline/report/Report'));

const KGMobility = React.lazy(() => import('./views/popup/kg-mobility/index'));


const loading = (
  <div className="renderLoading">
    <div className="loader"></div>
  </div>
);

const RouteFile = () => {
  return (
    <React.Suspense>
      <Routes>
        <Route path="installer">
          {/*<Route path="" element={<Home/>}/>*/}
          <Route path="login">
            <Route path="" element={<Login/>}/>
            <Route path="self" element={<SelfAuthentication/>}/>
            <Route path="auth" element={<Authentication/>}/>
            <Route path="agree" element={<Agree/>}/>
            <Route path="complete" element={<Complete/>}/>
          </Route>
          <Route path="fbp-model" element={<CarInfoSelect/>}/>
          <Route path="connecting">
            <Route path="upload" element={<InfoUpload/>}/>
            <Route path="check-led" element={<InfoLEDCheck/>}/>
            <Route path="check-connected" element={<ConnectedCheck/>}/>
            <Route path="complete" element={<LEDComplete/>}/>
          </Route>
        </Route>
        <Route path="timeline">
          <Route path="" element={<Timeline/>}/>
          <Route path="report" element={<Report/>}/>
        </Route>
        <Route path="recent-info">
          <Route path="safe-score" element={<SafeReport/>}/>
        </Route>
        <Route path="event">
        </Route>
        <Route path="popup">
          <Route path="kg-mobility" element={<KGMobility/>}/>
        </Route>
        <Route path="*" element={<NoMatch/>}/>

      </Routes>
    </React.Suspense>
  );
};
export default RouteFile;
