import ApiRequest from "./ApiRequest";
import qs from "qs";

const Api = new ApiRequest(process.env.REACT_APP_API_URL);


// installer
// Login - 기업코드 체크
export function loginCompanyCode(companyCode) {
  return Api.get(`/install-service/v1/check-company-code/${companyCode}`, null);
}

// Login -테스트용 전화번호 로그인
export function testMobileNoLogin(companyCode, mobileNo) {
  return Api.get(`/authentication-service/v1/company/${companyCode}/mobile/${mobileNo}`, null);
}

// Login - 엔지니어 약관동의 정보 저장
export function postAgreements(token, body) {
  return Api.post(`/api/install-service/v1/agreements`, token, body);
}

// Login - 토큰 로그인
export function loginByToken(token) {
  return Api.post('/api/install-service/v1/token-login', token);
}

// fbp-model - 차량모델 분류 조회
export function getFbpCategories(token, data) {
  return Api.get(`/api/install-service/v1/fbp-model/categories?categories=${data}`, token);
}

// fbp-model - 차량모델 상세 조회
export function getFbpFilePath(token, data) {
  return Api.get(`/api/install-service/v1/fbp-model?categories=${data}`, token);
}

// 차량등록
export function registerVehicle(token, body) {
  return Api.post(`/api/install-service/v1/vehicle`, token, body);
}

// 차량등록 취소
export function deleteRegisterVehicle(token, carNum) {
  return Api.delete(`/api/install-service/v1/vehicle/${carNum}`, token);
}

// 차량 등록 - 커넥티드 데이터 확인
export function getConnectedData(token, carNum) {
  return Api.get(`/api/install-service/v1/vehicle/${carNum}/data`, token);
}

// 차량등록 완료
export function completeVehicleRegistration(token, carNum) {
  return Api.put(`/api/install-service/v1/vehicle/${carNum}`, token);
}


// itlink - 타임라인
// 알람 조회
export function getTimelineNotification(token, query) {
  if (!query.carNumList) {
    return Api.get(`/api/member-service/v1/timeline`, token, query);
  } else {
    const list = [...query.carNumList];
    delete query.carNumList;
    let newQuery = qs.stringify(query) + `&carNumList=${list}`;
    return Api.get(`/api/member-service/v1/timeline?${newQuery}`, token);
  }
}

// 검색차량
export function getVehicleList(token) {
  return Api.get(`/api/member-service/v1/timeline/vehicle-list`, token);
}

// 조회수 업데이트
export function putTimelineViewCount(token, timelineIds) {
  return Api.put(`/api/member-service/v1/timeline/view-count`, token, {timelineIds});
}

// 월간리포트 데이터 조회호출

export function getReportMonthly(token, carNum, firstDayMilliOfMonth) {
  return Api.get(`/api/member-service/v1/report/monthly?carNum=${carNum}&firstDayMilliOfMonth=${firstDayMilliOfMonth}`, token);
}

export function getSafeScore(token, carNum) {
  return Api.get(`/api/achakey-service/v1/vehicle/${carNum}/info/safe-drive-score/indicators`, token);
}
