import moment from 'moment';

export const Format = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  YY_MM_DD: 'YY-MM-DD',
  YYYY_MM_DD_HHmmss: 'YYYY-MM-DD HH:mm:ss',
  YYYY_MM_DD_HHmmss_SSS: 'YYYY-MM-DD HH:mm:ss.SSS ',
  YYYY_MM_DD_HHmm: 'YYYY-MM-DD HH:mm',
  YYYY_MM: 'YYYY-MM',
  YYYYMM: 'YYYYMM',
  KR_MM_DD: 'MM월 DD일',
  HH_mm_ss: 'HH:mm:ss',
  LT: 'LT',
  YYYY: 'YYYY',
  KR_YY_MM_DD: 'YY년 MM월 DD일',
  KR_YYYY_MM_DD: 'YYYY년 MM월 DD일'
};

export function format(value, format = Format.YYYY_MM_DD_HHmmss) {
  return moment(value).format(format);
}

export function toTimeStamp(value) {
  return moment(value).toDate().getTime();
}

export function toStartOfTimeStamp(value) {
  return moment(value).startOf('day').toDate().getTime();
}

export function toEndOfTimeStamp(value) {
  return moment(value).endOf('day').toDate().getTime();
}

export function toStartOfMonthTimeStamp(value) {
  return moment(value).startOf('month').toDate().getTime();
}

export function toEndOfMonthTimeStamp(value) {
  return moment(value).endOf('month').toDate().getTime();
}

export function toStartOfYearTimeStamp(value) {
  return moment(value).startOf('year').toDate().getTime();
}

export function toEndOfYearTimeStamp(value) {
  return moment(value).endOf('year').toDate().getTime();
}

export function diff(startTimeStamp, endTimeStamp, unitOfTime) {
  return Math.abs(moment(startTimeStamp).diff(moment(endTimeStamp), unitOfTime));
}

// 현재 시간으로부터 증가 또는 감소
export function add(amount, unit) {
  return moment().add(amount, unit).toDate().getTime();
}

export function now() {
  return moment().toDate().getTime();
}

// value는 new Date() ex) Tue Oct 18 2022 17:58:00 GMT+0900 (한국 표준시)
export function addValue(value, amount, unit) {
  return moment(value).add(amount, unit).toDate().getTime();
}

