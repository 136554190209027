import styled from "styled-components";
import { webUtil } from "../utils/webAppUtil";

export const styles = {
  bodyBackgroundColor: "#000",
  mainFontColor: "#FFFFFF",
  descFontColor: "#96969d",
  labelFontColor: "#999",
  errorLabelFontColor: '#ff453a',
  btnDisableBgColor: "#1c1c1e",
};

export const BodyStyle = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  color: ${styles.mainFontColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

webUtil(); //  window.webAppUtil.isAndroid() 를 사용하기 위해 필요함

export const MainStyle = styled(BodyStyle)`
  padding: 4.5rem 1.25rem 0;
  padding-bottom: ${window.webAppUtil.isAndroid() ? '1.25rem' : '0'}; // 안드로이드만 적용
`;

export const DefaultStyle = styled(BodyStyle)`
  padding: 2.125rem 1.25rem 0;
`;

export const BackStyle = styled(BodyStyle)`
  padding: 0 1.25rem ;
  justify-content: space-between;
`;

export const TimelineBackStyle = styled(BackStyle)`
  overflow: unset;
  justify-content: flex-start;
`;
