import React, {useEffect, useState} from 'react';
import {createEventBusHelper} from '../utils/EventBusHelper';
import {useLocation, useNavigate} from "react-router-dom";
import * as CookieUtil from "../utils/CookieUtil";
import DialogManager from "../dialog/DialogManager";
import queryString from "query-string";

const EventBusHelper = createEventBusHelper();

const AppContext = React.createContext();

const AppProvider = ({children}) => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const decodeSearch = decodeURI(search);
  const {isTablet, language} = queryString.parse(decodeSearch);

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const [user, setUser] = React.useState();
  const [authToken, setAuthToken] = React.useState(CookieUtil.getAuthorization());
  const [roles, setRoles] = React.useState([]);
  const [androidIsTablet, setAndroidIsTablet] = useState(isTablet || false);

  const setAuthTokenWrapper = (data) => {
    setAuthToken(data);
  };

  const store = {
    setLoading,
    user, setUser,
    authToken, setAuthToken: setAuthTokenWrapper,
    roles, setRoles, androidIsTablet
  };

  useEffect(() => {
    setLoaded(true);
    if (language !== "ko" && language !== "en") {
      CookieUtil.setUserLanguage("en");
    } else {
      CookieUtil.setUserLanguage(language);
    }
    return () => {
      EventBusHelper.removeAllListeners();
    };
  }, []);

  const renderLoading = () => (
    <div className="renderLoading">
      <div className="loader"></div>
    </div>
  );

  return (
    <AppContext.Provider value={store}>
      {loaded && children}
      <DialogManager/>
      {loading && renderLoading()}
    </AppContext.Provider>
  );
};

export {
  AppProvider,
  AppContext,
};
