import React, { useContext, useEffect, useState } from 'react';
import './modalPage.css';
import styled, { css } from 'styled-components';
import { AppContext } from "../contexts/AppContext";

const PopUpModalContent = ({onClose, content}) => {
  const {androidIsTablet} = useContext(AppContext);
  const [isClose, setIsClose] = useState(false);

  useEffect(() => {
    document.body.style.cssText = `
    position: fixed; 
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);


  const onSetClose = (value) => {
    setIsClose(true);
    setTimeout(() => onClose(value), 300);
  };

  return (
    <div className="MModal">
      <div id="modal-container" className={isClose ? "two out" : "two"}>
        <div className="modal-background">
          <Modal className="custom-modal" isTablet={androidIsTablet}>
            <div className="content">
              {content}
            </div>
            <div className="confirm" onClick={() => onSetClose('확인')}>확인</div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PopUpModalContent;

const Modal = styled.div`
  width: 80%;
  height: 13.5rem;

  ${props => props.isTablet === "true" && css`
    width: 60%;
  `}

`;

