import React from 'react';

const useGetGraphData = (data) => {
  const LABEL = data ? data.map((item) => {
    return item.x;
  }) : [];
  const VALUE = data ? data.map((item) => {
    return item.y;
  }) : [];

  const highlightGraph = () => {
    const maxValue = Math.max.apply(null, VALUE);
    const secondValue = () => {
      const noDupArray = new Set(VALUE);
      return [...noDupArray].sort((a, b) => b - a)[1];
    };
    const minValue = Math.min.apply(null, VALUE);

    return VALUE.map((value) => {
      switch (value) {
        case maxValue:
          return "#FF2D55";
        case secondValue():
          return  "#FF9F0A";
        case minValue:
          return "#5E5CE6";
        default:
          return "#64D2FF";
      }
    })
  };


  const barData = {
    labels: LABEL,
    datasets: [{
      label: '# of Votes',
      data: VALUE,
      backgroundColor: highlightGraph(),
    }],
  }

  return {barData};
};

export default useGetGraphData;