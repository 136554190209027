import React from 'react';
import PopUpModalContent from "./index";

const PopUpModalContainer = ({ onClose, content}) => {

  return (
    <div>
      <PopUpModalContent onClose={onClose} content={content}/>
    </div>
  );
};

export default PopUpModalContainer;
