import get from 'lodash.get';

export function getWindowInnerWidth() {
  if (window.visualViewport) {
    return window.visualViewport.width;
  } else {
    return window.innerWidth;
  }
}



// native app으로 메시지 전달
export function postMessageToApp(data) {
  const android = get(window, 'native');
  const ios = get(window, 'webkit.messageHandlers.native');
  const reactNative = get(window, 'ReactNativeWebView');
  if (ios) {
    ios.postMessage(data);
  }
  if (android) {
    android.postMessage(JSON.stringify(data));
  }
  if (reactNative) {
    reactNative.postMessage(JSON.stringify(data));
  }
};
