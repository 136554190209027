import React from 'react';

export const BackArrow = () => (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="#FFF" fillRule="evenodd" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.545.4.387 9.1a1.238 1.238 0 0 0 0 1.799l9.158 8.7a1.461 1.461 0 0 0 2.01 0 1.427 1.427 0 0 0 0-2.072l-7.67-7.288a.33.33 0 0 1 0-.481l7.67-7.288a1.427 1.427 0 0 0 0-2.072 1.461 1.461 0 0 0-2.01 0z"/>
  </svg>
);


export const CheckIcon = ({value}) => (
  <svg
    className={`checkbox ${value ? "checkbox--active" : ""}`}
    width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="nonzero" stroke="#666666">
      <path
        d="m14.856 8.102-5.428 5.385c-.32.32-.83.34-1.171.022L5.383 10.89a.863.863 0 0 1-.063-1.192.842.842 0 0 1 1.192-.042l2.277 2.086 4.853-4.853a.848.848 0 0 1 1.214 0c.34.34.34.872 0 1.213z"/>
    </g>
  </svg>
);

export const PrevArrow = () => (
  <svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
    <g fill="#656569" transform="matrix(-1 0 0 1 8 0)" fillRule="evenodd">
      <path
        d="M5.568.24.226 5.46a.758.758 0 0 0 0 1.08l5.342 5.22c.327.32.844.32 1.172 0a.873.873 0 0 0 0-1.243L2.266 6.144a.202.202 0 0 1 0-.289L6.74 1.482a.873.873 0 0 0 0-1.243.84.84 0 0 0-1.172 0z"/>
    </g>
  </svg>
);

export const CompleteIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill="#F26F20" cx="30" cy="30" r="30"/>
      <path fill="#FFF"
            d="M18.506 27.492a.985.985 0 0 0-1.405-.01l-2.404 2.43a1.013 1.013 0 0 0 .01 1.42l9.627 9.725a1.03 1.03 0 0 0 1.43.028l19.515-18.559c.398-.379.41-1 .024-1.39L42.9 18.704a1.028 1.028 0 0 0-1.423-.023l-15.708 14.73a1.03 1.03 0 0 1-1.434-.034l-5.828-5.885z"/>
    </g>
  </svg>
);

export const FailIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth="2" cx="30" cy="30" r="29"/>
      <path fill="#FFF"
            d="M27.401 19.08c0-1.425 1.172-2.58 2.616-2.58 1.445 0 2.617 1.155 2.617 2.58V31.53c0 1.425-1.172 2.58-2.617 2.58-1.444 0-2.616-1.155-2.616-2.58V19.08zM30 42.9c-1.657 0-3-1.325-3-2.959 0-1.634 1.343-2.959 3-2.959s3 1.325 3 2.96c0 1.633-1.343 2.958-3 2.958z"
            fillRule="nonzero"/>
    </g>
  </svg>
);

export const SuccessIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth="2" cx="30" cy="30" r="29"/>
      <path fill="#FFF"
            d="M21.559 28.3a.993.993 0 0 0-1.415-.005l-1.44 1.456c-.389.393-.39 1.03.002 1.425l6.87 6.94a1.03 1.03 0 0 0 1.432.022L41.273 24.57a.979.979 0 0 0 .023-1.403l-1.44-1.456a1.037 1.037 0 0 0-1.433-.029L27.01 32.385a1.036 1.036 0 0 1-1.439-.034L21.56 28.3z"/>
    </g>
  </svg>
);

export const FilterIcon = ({isFilter}) => (
  <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
    <g fill={isFilter ? "#FFF" : "#F26F20"} fillRule="evenodd">
      <path
        d="M4.21 8.17V1c0-.552-.47-1-1.052-1-.581 0-1.053.448-1.053 1v7.17C.88 8.583 0 9.695 0 11s.879 2.418 2.105 2.83V17c0 .552.472 1 1.053 1s1.053-.448 1.053-1v-3.17c1.226-.412 2.105-1.524 2.105-2.83S5.437 8.582 4.21 8.17zM3.159 10c-.581 0-1.053.448-1.053 1s.472 1 1.053 1 1.053-.448 1.053-1-.472-1-1.053-1zM16.842 18c-.581 0-1.053-.448-1.053-1v-2a.96.96 0 0 1 .015-.166c-1.234-.408-2.12-1.523-2.12-2.834 0-1.311.886-2.426 2.12-2.834A.96.96 0 0 1 15.789 9V1c0-.552.472-1 1.053-1s1.053.448 1.053 1v8a.96.96 0 0 1-.015.166C19.114 9.574 20 10.689 20 12c0 1.311-.886 2.426-2.12 2.834a.96.96 0 0 1 .015.166v2c0 .552-.472 1-1.053 1zm-1.053-6c0-.552.472-1 1.053-1s1.053.448 1.053 1-.472 1-1.053 1-1.053-.448-1.053-1zM6.842 6c0-1.306.879-2.417 2.105-2.83V1c0-.552.472-1 1.053-1s1.053.448 1.053 1v2.17c1.226.413 2.105 1.524 2.105 2.83 0 1.311-.886 2.426-2.12 2.834a.96.96 0 0 1 .015.166v8c0 .552-.472 1-1.053 1s-1.053-.448-1.053-1V9a.96.96 0 0 1 .015-.166C7.728 8.426 6.842 7.311 6.842 6zm2.105 0c0-.552.472-1 1.053-1s1.053.448 1.053 1S10.58 7 10 7s-1.053-.448-1.053-1z"/>
    </g>
  </svg>
);

export const NoAlarm = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" strokeWidth="2" cx="30" cy="30" r="29"/>
      <g fill="#FFF" fillRule="nonzero">
        <path
          d="M37.25 21H36v-1a1 1 0 1 0-2 0v1h-8v-1a1 1 0 1 0-2 0v1h-1.25A2.752 2.752 0 0 0 20 23.75V35c0 1.654 1.346 3 3 3h5a1 1 0 1 0 0-2h-5a1 1 0 0 1-1-1v-8h16a1 1 0 1 0 2 0v-3.25A2.752 2.752 0 0 0 37.25 21z"/>
        <path
          d="M37.5 30a6.507 6.507 0 0 0-6.5 6.5c0 3.584 2.916 6.5 6.5 6.5s6.5-2.916 6.5-6.5-2.916-6.5-6.5-6.5zm3.006 5.405-3.25 3.75c-.182.21-.443.334-.72.345H36.5a.999.999 0 0 1-.707-.293l-1.75-1.75a.999.999 0 1 1 1.414-1.414l.991.991 2.546-2.938a1 1 0 0 1 1.512 1.309z"/>
      </g>
    </g>
  </svg>
);

export const SearchIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m15.212 14.573-3.808-3.96A6.44 6.44 0 0 0 12.92 6.46 6.467 6.467 0 0 0 6.46 0 6.467 6.467 0 0 0 0 6.46a6.467 6.467 0 0 0 6.46 6.46 6.39 6.39 0 0 0 3.701-1.169l3.837 3.99a.838.838 0 0 0 1.191.023.844.844 0 0 0 .023-1.19zM6.46 1.685a4.78 4.78 0 0 1 4.775 4.775 4.78 4.78 0 0 1-4.775 4.775A4.78 4.78 0 0 1 1.685 6.46 4.78 4.78 0 0 1 6.46 1.685z"
      fill="#999" fillRule="nonzero"/>
  </svg>
);

export const DelIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0a9.939 9.939 0 0 0-7.074 2.93c-3.901 3.901-3.901 10.248 0 14.149A9.94 9.94 0 0 0 10 20.009a9.94 9.94 0 0 0 7.074-2.93c3.901-3.9 3.901-10.248 0-14.149A9.94 9.94 0 0 0 10 0zm4.502 14.506a.907.907 0 0 1-1.286 0L10 11.291l-3.216 3.216a.907.907 0 0 1-1.286 0 .91.91 0 0 1 0-1.287l3.216-3.215-3.216-3.216a.91.91 0 1 1 1.286-1.286L10 8.718l3.216-3.215a.91.91 0 0 1 1.286 1.286l-3.216 3.216 3.216 3.215a.91.91 0 0 1 0 1.286z"
      fill="#FFF" fillRule="nonzero"/>
  </svg>
);

export const AchakeyIcon = ({width, height}) => (
  <svg width={width} height={height} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.98 37.979 20.002 2.017 2.01 37.987h7.114L20.015 16.24l2.242 4.477-3.381 6.75h6.708l2.053 4.028H16.858l-3.22 6.484"
      fill="#FFF" fillRule="nonzero"/>
  </svg>
);

export const CalendarArrow = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
    <path fill="#FFF" fillRule="evenodd"
          d="M5.384 5.54 9.317.82A.5.5 0 0 0 8.932 0H1.068a.5.5 0 0 0-.385.82l3.933 4.72a.5.5 0 0 0 .768 0z"/>
  </svg>
);

export const ArrowRight = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" >
    <defs>
      <linearGradient x1="36.176%" y1="23.839%" x2="83.165%" y2="100%" id="8iam53gosc">
        <stop stopColor="#FFF" offset="0%"/>
        <stop stopColor="#CFD1D8" offset="100%"/>
      </linearGradient>
      <filter x="-22.9%" y="-14.6%" width="145.8%" height="145.8%" filterUnits="objectBoundingBox" id="4v2xq1jw5a">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1"/>
      </filter>
      <circle id="0ssib36bkb" cx="12" cy="12" r="12"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(3 1)">
        <use fill="#000" filter="url(#4v2xq1jw5a)" />
        <circle stroke="#FFF" strokeWidth="1.5" strokeLinejoin="square" fill="url(#8iam53gosc)" cx="12" cy="12" r="11.25"/>
      </g>
      <path stroke="#000" fill="#000" d="M19.286 13a.837.837 0 0 0-.247-.594l-5.172-5.16a.844.844 0 0 0-1.192 0 .839.839 0 0 0 0 1.189L17.252 13l-4.577 4.565a.839.839 0 0 0 0 1.189.844.844 0 0 0 1.192 0l5.172-5.16a.837.837 0 0 0 .247-.594z"  fillRule="nonzero"/>
    </g>
  </svg>
)


export const ArrowLeft = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" >
    <defs>
      <linearGradient x1="36.176%" y1="23.839%" x2="83.165%" y2="100%" id="6g2ze6k0vc">
        <stop stopColor="#FFF" offset="0%"/>
        <stop stopColor="#CFD1D8" offset="100%"/>
      </linearGradient>
      <filter x="-22.9%" y="-14.6%" width="145.8%" height="145.8%" filterUnits="objectBoundingBox" id="hs212tys3a">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" in="shadowBlurOuter1"/>
      </filter>
      <circle id="kiinv90bgb" cx="12" cy="12" r="12"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(3 1)">
        <use fill="#000" filter="url(#hs212tys3a)" />
        <circle stroke="#FFF" strokeWidth="1.5" strokeLinejoin="square" fill="url(#6g2ze6k0vc)" cx="12" cy="12" r="11.25"/>
      </g>
      <path d="M10.714 13c0-.215.083-.43.247-.594l5.172-5.16a.844.844 0 0 1 1.192 0 .839.839 0 0 1 0 1.189L12.748 13l4.577 4.565a.839.839 0 0 1 0 1.189.844.844 0 0 1-1.192 0l-5.172-5.16a.837.837 0 0 1-.247-.594z" stroke="#000" fill="#000" fillRule="nonzero"/>
    </g>
  </svg>

)
