import React from 'react';
import { BackArrow } from "./svg/svg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { postMessageToApp } from "../utils/Utils";

const TitleBarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  position: sticky;
  top: 0;
  background-color: #000;

  span {
    font-size: 1.125rem;
    font-weight: 600;
  }

  .backArrow-wrapper {
    padding: 0.2rem;
    position: absolute;
    left: 0;
  }
  margin-bottom: 1.3rem;
`;


const TitleBar = ({content}) => {
  const onClick = () => {
      postMessageToApp({action : 'close'})
  }

  return (
    <TitleBarWrapper>
      <div className="backArrow-wrapper" onClick={onClick}>
        <BackArrow/>
      </div>
      <span>{content}</span>
    </TitleBarWrapper>
  );
};

export default TitleBar;
