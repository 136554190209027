import React, { useEffect } from 'react';
import './app.css';
import { createGlobalStyle } from 'styled-components';
import { styles } from './components/styles.js';
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./contexts/AppContext";
import RouteFile from "./RouteFile";
import 'bootstrap/dist/css/bootstrap.min.css';
import './src_assets/fonts/pretendard/pretendardvariable-dynamic-subset.css';

import SpoqaHansRegular from './src_assets/fonts/SpoqaHanSans/SpoqaHanSansRegular.woff2';
import SpoqaHansBold from './src_assets/fonts/SpoqaHanSans/SpoqaHanSansBold.woff2';
import InterRegular from './src_assets/fonts/Inter/Inter-Regular.ttf';
import InterBold from './src_assets/fonts/Inter/Inter-Bold.ttf';

function App() {
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    let wh = window.innerWidth * 0.01;
    console.log(`vh : ${vh * 100}px, wh : ${wh * 100}px`);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty("--wh", `${wh}px`);
  }

  useEffect(() => {
    setScreenSize();
  });

  return (
    <>
      <GlobalStyle/>
      <BrowserRouter>
        <AppProvider>
          <RouteFile/>
        </AppProvider>
      </BrowserRouter>
    </>
  );
}

export default App;


const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, menu, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }

  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }


  @font-face {
    font-family: 'Spoqa Han Sans';
    src: local("Spoqa Han Sans"),
    url(${SpoqaHansRegular}) format('woff2');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Spoqa Han Sans';
    src: local('Nanum Gothic'),
    url(${InterRegular}) format('truetype');
    font-weight: 400;
    unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
  }

  @font-face {
    font-family: 'Spoqa Han Sans';
    src: local("Spoqa Han Sans"),
    url(${SpoqaHansBold}) format('woff2');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Spoqa Han Sans';
    src: local('Nanum Gothic'),
    url(${InterBold}) format('truetype');
    font-weight: 700;
    unicode-range: U+0030-0039, U+0061-007A, U+0041-005A;
  }


  body {
    line-height: 1;
    font-family: 'Spoqa Han Sans';
  }

  menu, ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  :root {
    --vh: 100%; // 사용자의 뷰포트 계산하기
    color-scheme: dark;
  }

  * {
    box-sizing: border-box;
    word-break: keep-all;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); // 버튼 Touch시 나오는 음영 지우기
    //user-select: none; // 텍스트 셀렉트 안되게
    -webkit-touch-callout: none; // 롱터치 막음

  }

  body {
    background-color: ${styles.bodyBackgroundColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    color: inherit;
    font: inherit;
    background-color: transparent;
    cursor: default;
  }
`;
