import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 1.5rem 1.5rem 0;
`;

export const Footer = styled.div`
  height: 3.75rem;
  background-color: #1c1c1e;
  font-size: 0.813rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.5px;
  text-align: left;
  color: #999999;
  padding: 1rem 1.5rem;
`;

export const GraphWrapper = styled.div`
  padding: 2.5rem 0;
`;

export const Title = styled.div`
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  margin-bottom: 0.5rem;

  .name {
    color: #ffb900;
    margin-right: 0.5rem;
  }

  .info1 {
    margin-right: 0.25rem;
  }
`;

export const Description = styled.div`
  font-size: 0.825rem;

  .comparison {
    margin-right: 0.25rem;
  }

  .result.increase {
    color: #ff0000;
  }

  .result.decrease {
    color: #0094ff;
  }
`;
