import React, {useEffect, useState} from 'react';
import {Description, GraphWrapper, Wrapper} from "../../timeline/report/graphes/graphesStyle";
import {Bar} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import {BackStyle} from "../../../components/styles";
import TitleBar from "../../../components/TitleBar";
import styled from "styled-components";
import DrivingInfo from "./DrivingInfo";
import WeekStatistics from "./WeekStatistics";
import YearStatistics from "./YearStatistics";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import * as ApiErrorHandler from "../../../apis/ApiErrorHandler";
import * as Apis from "../../../apis/Apis";
import * as CookieUtil from "../../../utils/CookieUtil";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Tooltip,
  Legend
);

const Index = () => {
  const {search} = useLocation();
  const decodeSearch = decodeURI(search);
  const {token, carNum, language} = queryString.parse(decodeSearch);
  const [data, setData] = useState({});

  const getSafeScore = async () => {
    try {
      const res = await Apis.getSafeScore(token, carNum);
      setData(res);
    } catch (e) {
      ApiErrorHandler.allCode(e);
    }
  }

  useEffect(() => {
    CookieUtil.setUserLanguage(language);
    getSafeScore();
  }, []);

  return (
    <BackStyle>
      <Container>
        <DrivingInfo data={data}/>
        {data?.timestamp && (
          <>
            <hr/>
            <WeekStatistics data={data?.safeDriveScoreFor1Week}/>
            <hr/>
            <YearStatistics data={data?.safeDriveScoreFor12Months}/>
          </>
        )}
      </Container>
    </BackStyle>
  );
};

export default Index;

const Container = styled.section`
  height: 100%;
`;